import React from "react"
import { graphql } from "gatsby"
import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import TwoCol from "gatsby-theme-wirehead-bulma/src/twocol"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import PostLink from "../components/post-link"
import SchemaCollection from "../components/schema-collection"

export default function CollectionTemplate({ data, location, pageContext }) {
  const { mdx, allSitePage } = data
  const { body } = mdx

  var pages = data.allSitePage.edges.map(edge => {
    return <PostLink key={edge.node.path} post={edge} />
  })

  return (
    <Layout posted={pageContext.date} updated={pageContext.updated} >
      <SEO
        title={pageContext.title}
        pathname={location.pathname}
        description={pageContext.excerpt}
      />
      <SchemaCollection
        pathname={location.pathname}
        pages={data.allSitePage.edges}
        description={pageContext.excerpt}
        breadcrumbs={pageContext.breadcrumbs}
      />
      <h1 className="title is-2">{pageContext.title}</h1>
      <section className="section">
      <TwoCol
        left={
          <div className="content">
            <MDXRenderer pages={allSitePage}>{body}</MDXRenderer>
            {pages}
          </div>
        }
        right={
          <>
          </>
        }
      />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String, $path: String) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        type
        description
      }
      excerpt(pruneLength: 300)
    }
    allSitePage(sort: { fields: context___date, order: DESC } filter: { context: { parent: { eq: $path } } }) {
      edges {
        node {
          context {
            id
            name
            parent
            title
            depth
            date
            excerpt
            type
          }
          id
          path
        }
      }
    }
  }
`
