/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { collection, graphWrapper, person, } from "schema-comsat"
import { convertBreadcrumb } from "./schema-translate"

function SchemaCollection({ breadcrumbs, pathname, pages, description }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            canonicalUrl
          }
        }
      }
    `
  )

  const rootUrl = site.siteMetadata.canonicalUrl

  const schemaPages = pages.map(edge => {
    return { "@id": rootUrl + edge.node.path }
  })

  const schema = graphWrapper([
    collection(
    {
      "id": rootUrl + pathname,
      hasPart: schemaPages,
      description: description,
      producer: person({name: site.siteMetadata.author, url: rootUrl}),
    }),
    convertBreadcrumb(rootUrl, breadcrumbs)
  ])

  return (
    <Helmet>
      <meta name="twitter:card" content="summary" />
      <meta property="og:type" content="article" />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

SchemaCollection.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object),
  pathname: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.any,
}

export default SchemaCollection
